<template>
    <InputMask v-model="textValue" mask="99999-999" placeholder="CEP" :class="(error && !cep) && 'p-invalid'"
        @change="checkInput" />
    <p class="p-invalid" v-if="error && !cep">
        {{ error }}
    </p>
    <p class="p-invalid" v-if="errorUf.status">
        {{ errorUf.msg }}
    </p>
</template>

<script>
import { watch, onMounted, ref } from "vue";

import {
    GetAddressRequest,
} from "../../modules/core/grpc/generated/address_pb";
import addressService from "../../modules/core/services/addressService";

export default {
    props: [
        "cep",
        "state",
        "city",
        "address",
        "neighborhood",
        "type",
        "unique",
        "error",
        "disabled"
    ],
    emits: [
        "update:cep",
        "update:state",
        "update:city",
        "update:address",
        "update:neighborhood",
        "update:type",
        "update:unique",
    ],
    setup(props, { emit }) {
        const errorUf = ref({status: false, msg: ""});
        const textValue = ref("");

        const checkInput = async e => {
            let value = e.target.value;
            const cep = value = value.replace('-', '');

            if (cep.length < 8) {
                textValue.value = ""
                if(!props.disabled){
                    emit('update:cep', "")
                }
            } else {
                if (cep.length === 8) {
                    await GetAddress(cep);
                    emit('update:cep', cep)
                }
            }
        }

        const GetAddress = async postalCode => {
            try {
                let req = new GetAddressRequest();
                req.setPostalcode(postalCode);

                let res = await addressService.getAddress(req);

                if (res.success) {
                    const address = JSON.parse(res.data)

                    if (address) {
                        const { state, city, neighborhood, street } = address;

                        if (props.disabled) {
                            if (state === props.state) {
                                emit('update:type', 'cep')
                                if (address.hasOwnProperty('city')) {
                                    if (city) {
                                        emit('update:city', city)
                                    } else {
                                        emit('update:city', '')
                                    }
                                }
                                if (address.hasOwnProperty('street')) {
                                    if (street && street !== ' ') {
                                        emit('update:address', street)
                                    } else {
                                        emit('update:address', '')
                                    }
                                }

                                if (address.hasOwnProperty('neighborhood')) {
                                    if (neighborhood) {
                                        emit('update:neighborhood', neighborhood)
                                        emit('update:unique', false)
                                    } else {
                                        emit('update:neighborhood', '')
                                        emit('update:unique', true)
                                    }
                                }
                                errorUf.value = {status: false, msg: ""}
                            } else {
                                errorUf.value = {status: true, msg: 'O CEP informado não pertence a mesma UF do local.'}
                            }

                        } else {
                            emit('update:type', 'cep')
                            if (address.hasOwnProperty('state')) {
                                if (state) {
                                    emit('update:state', state)
                                }
                            }
                            if (address.hasOwnProperty('city')) {
                                if (city) {
                                    emit('update:city', city)
                                } else {
                                    emit('update:city', '')
                                }
                            }

                            if (address.hasOwnProperty('street')) {
                                if (street && street !== ' ') {
                                    emit('update:address', street)
                                } else {
                                    emit('update:address', '')
                                }
                            }

                            if (address.hasOwnProperty('neighborhood')) {
                                if (neighborhood) {
                                    emit('update:neighborhood', neighborhood)
                                    emit('update:unique', false)
                                } else {
                                    emit('update:neighborhood', '')
                                    emit('update:unique', true)
                                }
                            }
                            errorUf.value = {status: false, msg: ""}
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }

        watch(
            () => props.cep,
            (newValue, oldValue) => {
                if (newValue !== textValue.value.replace('-', '')) {
                    textValue.value = newValue
                }
            }
        );

        watch(
            () => props.state,
            (newValue, oldValue) => {
                if (props.type === 'state' && newValue) {
                    textValue.value = "";
                }
            }
        );

        onMounted(() => {
            if (props.cep) {
                textValue.value = props.cep;
            }
        })

        return {
            textValue,
            checkInput,
            errorUf
        }
    },
};
</script>


<style lang="scss" scoped>

</style>