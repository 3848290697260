/**
 * @fileoverview gRPC-Web generated client stub for addressApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.18.1
// source: address.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.addressApi = require('./address_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.addressApi.AddressProtoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.addressApi.AddressProtoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.addressApi.EmptyRequest,
 *   !proto.addressApi.ListStatesResponse>}
 */
const methodDescriptor_AddressProtoService_ListStates = new grpc.web.MethodDescriptor(
  '/addressApi.AddressProtoService/ListStates',
  grpc.web.MethodType.UNARY,
  proto.addressApi.EmptyRequest,
  proto.addressApi.ListStatesResponse,
  /**
   * @param {!proto.addressApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.addressApi.ListStatesResponse.deserializeBinary
);


/**
 * @param {!proto.addressApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.addressApi.ListStatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.addressApi.ListStatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.addressApi.AddressProtoServiceClient.prototype.listStates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/addressApi.AddressProtoService/ListStates',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_ListStates,
      callback);
};


/**
 * @param {!proto.addressApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.addressApi.ListStatesResponse>}
 *     Promise that resolves to the response
 */
proto.addressApi.AddressProtoServicePromiseClient.prototype.listStates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/addressApi.AddressProtoService/ListStates',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_ListStates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.addressApi.GetAddressRequest,
 *   !proto.addressApi.ServiceResponse>}
 */
const methodDescriptor_AddressProtoService_GetAddress = new grpc.web.MethodDescriptor(
  '/addressApi.AddressProtoService/GetAddress',
  grpc.web.MethodType.UNARY,
  proto.addressApi.GetAddressRequest,
  proto.addressApi.ServiceResponse,
  /**
   * @param {!proto.addressApi.GetAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.addressApi.ServiceResponse.deserializeBinary
);


/**
 * @param {!proto.addressApi.GetAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.addressApi.ServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.addressApi.ServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.addressApi.AddressProtoServiceClient.prototype.getAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/addressApi.AddressProtoService/GetAddress',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_GetAddress,
      callback);
};


/**
 * @param {!proto.addressApi.GetAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.addressApi.ServiceResponse>}
 *     Promise that resolves to the response
 */
proto.addressApi.AddressProtoServicePromiseClient.prototype.getAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/addressApi.AddressProtoService/GetAddress',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_GetAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.addressApi.SearchCityRequest,
 *   !proto.addressApi.ServiceResponse>}
 */
const methodDescriptor_AddressProtoService_SearchCity = new grpc.web.MethodDescriptor(
  '/addressApi.AddressProtoService/SearchCity',
  grpc.web.MethodType.UNARY,
  proto.addressApi.SearchCityRequest,
  proto.addressApi.ServiceResponse,
  /**
   * @param {!proto.addressApi.SearchCityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.addressApi.ServiceResponse.deserializeBinary
);


/**
 * @param {!proto.addressApi.SearchCityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.addressApi.ServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.addressApi.ServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.addressApi.AddressProtoServiceClient.prototype.searchCity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/addressApi.AddressProtoService/SearchCity',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_SearchCity,
      callback);
};


/**
 * @param {!proto.addressApi.SearchCityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.addressApi.ServiceResponse>}
 *     Promise that resolves to the response
 */
proto.addressApi.AddressProtoServicePromiseClient.prototype.searchCity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/addressApi.AddressProtoService/SearchCity',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_SearchCity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.addressApi.CheckCityRequest,
 *   !proto.addressApi.CheckCityResponse>}
 */
const methodDescriptor_AddressProtoService_CheckCity = new grpc.web.MethodDescriptor(
  '/addressApi.AddressProtoService/CheckCity',
  grpc.web.MethodType.UNARY,
  proto.addressApi.CheckCityRequest,
  proto.addressApi.CheckCityResponse,
  /**
   * @param {!proto.addressApi.CheckCityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.addressApi.CheckCityResponse.deserializeBinary
);


/**
 * @param {!proto.addressApi.CheckCityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.addressApi.CheckCityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.addressApi.CheckCityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.addressApi.AddressProtoServiceClient.prototype.checkCity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/addressApi.AddressProtoService/CheckCity',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_CheckCity,
      callback);
};


/**
 * @param {!proto.addressApi.CheckCityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.addressApi.CheckCityResponse>}
 *     Promise that resolves to the response
 */
proto.addressApi.AddressProtoServicePromiseClient.prototype.checkCity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/addressApi.AddressProtoService/CheckCity',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_CheckCity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.addressApi.SearchAddressRequest,
 *   !proto.addressApi.ServiceResponse>}
 */
const methodDescriptor_AddressProtoService_SearchAddress = new grpc.web.MethodDescriptor(
  '/addressApi.AddressProtoService/SearchAddress',
  grpc.web.MethodType.UNARY,
  proto.addressApi.SearchAddressRequest,
  proto.addressApi.ServiceResponse,
  /**
   * @param {!proto.addressApi.SearchAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.addressApi.ServiceResponse.deserializeBinary
);


/**
 * @param {!proto.addressApi.SearchAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.addressApi.ServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.addressApi.ServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.addressApi.AddressProtoServiceClient.prototype.searchAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/addressApi.AddressProtoService/SearchAddress',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_SearchAddress,
      callback);
};


/**
 * @param {!proto.addressApi.SearchAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.addressApi.ServiceResponse>}
 *     Promise that resolves to the response
 */
proto.addressApi.AddressProtoServicePromiseClient.prototype.searchAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/addressApi.AddressProtoService/SearchAddress',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_SearchAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.addressApi.CheckAddressRequest,
 *   !proto.addressApi.CheckAddressResponse>}
 */
const methodDescriptor_AddressProtoService_CheckAddress = new grpc.web.MethodDescriptor(
  '/addressApi.AddressProtoService/CheckAddress',
  grpc.web.MethodType.UNARY,
  proto.addressApi.CheckAddressRequest,
  proto.addressApi.CheckAddressResponse,
  /**
   * @param {!proto.addressApi.CheckAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.addressApi.CheckAddressResponse.deserializeBinary
);


/**
 * @param {!proto.addressApi.CheckAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.addressApi.CheckAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.addressApi.CheckAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.addressApi.AddressProtoServiceClient.prototype.checkAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/addressApi.AddressProtoService/CheckAddress',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_CheckAddress,
      callback);
};


/**
 * @param {!proto.addressApi.CheckAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.addressApi.CheckAddressResponse>}
 *     Promise that resolves to the response
 */
proto.addressApi.AddressProtoServicePromiseClient.prototype.checkAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/addressApi.AddressProtoService/CheckAddress',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_CheckAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.addressApi.SearchAddressRequest,
 *   !proto.addressApi.ServiceResponse>}
 */
const methodDescriptor_AddressProtoService_ListAddress = new grpc.web.MethodDescriptor(
  '/addressApi.AddressProtoService/ListAddress',
  grpc.web.MethodType.UNARY,
  proto.addressApi.SearchAddressRequest,
  proto.addressApi.ServiceResponse,
  /**
   * @param {!proto.addressApi.SearchAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.addressApi.ServiceResponse.deserializeBinary
);


/**
 * @param {!proto.addressApi.SearchAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.addressApi.ServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.addressApi.ServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.addressApi.AddressProtoServiceClient.prototype.listAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/addressApi.AddressProtoService/ListAddress',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_ListAddress,
      callback);
};


/**
 * @param {!proto.addressApi.SearchAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.addressApi.ServiceResponse>}
 *     Promise that resolves to the response
 */
proto.addressApi.AddressProtoServicePromiseClient.prototype.listAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/addressApi.AddressProtoService/ListAddress',
      request,
      metadata || {},
      methodDescriptor_AddressProtoService_ListAddress);
};


module.exports = proto.addressApi;

