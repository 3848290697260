<template>
    <Dropdown 
        v-model="valueState" 
        :class="(error && !state) && 'p-invalid'"
        :options="states" 
        optionLabel="code" 
        optionValue="code" 
        placeholder="Estado"
        @change="validateChange"
        :disabled="disabled"
    />
    <p class="p-invalid" v-if="error && !state">{{error}}</p>
</template>

<script>
import { watch, onMounted, ref } from "vue";
import { EmptyRequest } from "../../modules/core/grpc/generated/address_pb";
import addressService from "../../modules/core/services/addressService";

export default {
    props: ["cep", "state", "type", "error", "disabled"],
    emits: [
        "update:cep", 
        "update:state", 
        "update:type",
        "clear",
    ],
    setup(props, { emit }) {   
        const valueState = ref(props.state);
        const states = ref([]);

        const listStates = async () => {
            try {
                const arr = []
                let req = new EmptyRequest();
                let res = await addressService.listStates(req);

                res.statesList.forEach(state => {
                    arr.push({
                        code: state,
                    })
                })

                states.value = await arr
            } catch (error) {
                console.log(error)
            }
        }

        const validateChange = (e) => {
            emit("clear")
            emit("update:type", "state");
        }

        onMounted(async () => {
            await listStates()
        })

        watch(
            () => valueState.value,
            (newValue, oldValue) => {
                if(newValue){
                    emit("update:state", newValue);
                    
                }
            }
        );
        
        watch(
            () => props.state,
            (newValue, oldValue) => {
                if(newValue !== valueState.value){
                    valueState.value = newValue
                }
            }
        );

        return {
            valueState,
            states,
            listStates,
            validateChange
        }
    },
};
</script>


<style lang="scss" scoped>

:deep(){
    .p-dropdown-label{
        padding: 0 16px !important;
    }
}

</style>